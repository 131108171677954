import { useNavigate } from "react-router-dom";
import {
  FaPlane,
  FaHotel,
  FaGlobe,
  FaHandsHelping,
  FaUmbrellaBeach,
  FaShieldAlt,
} from "react-icons/fa";
import banner from "assets/main.jpg";
import imgLuxuryTravel from "assets/LuxuryTravel.jpg";

const Home = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate("/contact");
  };

  const testimonials = [
    {
      quote:
        "tblholidays has revolutionized our travel operations. Exceptional service and unmatched tools!",
      author: "Sarah P., Bright Horizons Travel",
    },
    {
      quote: "The platform is intuitive and robust—an indispensable asset.",
      author: "Daniel K., Global Reach Agency",
    },
    {
      quote: "Their support team is always available and incredibly helpful.",
      author: "Lisa M., SkyBound Tours",
    },
    {
      quote: "A perfect choice for managing luxury travel bookings.",
      author: "Alex R., LuxeWay Escapes",
    },
    {
      quote: "Streamlined, reliable, and perfect for businesses like ours.",
      author: "Emma T., WanderLine Solutions",
    },
  ];

  const faq = [
    {
      question: "How do I start using tblholidays?",
      answer:
        "Simply sign up and let our experts guide you through the quick onboarding process.",
    },
    {
      question: "Does tblholidays support bulk client management?",
      answer:
        "Yes, our tools are optimized for handling multiple accounts effortlessly.",
    },
    {
      question: "Can I access the platform on mobile?",
      answer: "Absolutely! Our mobile app offers full functionality on the go.",
    },
    {
      question: "What kind of customizations are available?",
      answer:
        "tblholidays adapts to your business needs with a variety of flexible options.",
    },
    {
      question: "Does the platform provide expense tracking?",
      answer:
        "Yes, our analytics tools offer detailed reports and tracking features.",
    },
    {
      question: "What is the level of security on the platform?",
      answer:
        "We ensure data safety with advanced encryption and secure protocols.",
    },
    {
      question: "Do you provide luxury travel options?",
      answer: "Yes, we specialize in exclusive travel experiences.",
    },
    {
      question: "How do I reach customer support?",
      answer:
        "Contact our team 24/7 through live chat, email, or phone for assistance.",
    },
  ];

  const additionalServices = [
    {
      title: "Worldwide Support",
      description:
        "With global coverage, we’re always close to you, no matter where you are.",
      icon: <FaGlobe className="text-5xl text-green-600 mx-auto mb-4" />,
    },
    {
      title: "Family Vacation Plans",
      description:
        "Discover tailored packages for memorable vacations with your loved ones.",
      icon: (
        <FaUmbrellaBeach className="text-5xl text-green-600 mx-auto mb-4" />
      ),
    },
    {
      title: "Advanced Security",
      description:
        "Your data is always secure with our industry-leading protection standards.",
      icon: <FaShieldAlt className="text-5xl text-green-600 mx-auto mb-4" />,
    },
  ];

  return (
    <div className="font-sans bg-gradient-to-b from-blue-100 via-green-50 to-blue-200 text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <img
          src={banner}
          alt="B2B Travel Platform"
          className="w-full h-[60vh] object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-green-500/40 to-blue-500/20 flex items-center justify-center">
          <div className="text-center px-8 py-12 max-w-4xl bg-white/90 rounded-lg shadow-lg">
            <h1 className="text-4xl font-extrabold text-green-800">
              Welcome to tblholidays
            </h1>
            <p className="text-lg mt-4 text-gray-700">
              Your gateway to seamless travel management solutions for the B2B
              industry.
            </p>
            <button
              onClick={handleContactClick}
              className="mt-6 bg-green-600 text-white px-8 py-3 rounded-lg shadow-md hover:bg-green-700"
            >
              Start Now
            </button>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <section className="py-12 bg-white">
        <h2 className="text-3xl font-bold text-center text-blue-600 mb-8">
          What We Offer
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
          {[
            {
              title: "Global Flights",
              description:
                "Access competitive rates for destinations worldwide.",
              icon: <FaPlane className="text-5xl text-blue-600 mx-auto mb-4" />,
            },
            {
              title: "Hotel Networks",
              description: "Book stays from over 15,000 hotels globally.",
              icon: <FaHotel className="text-5xl text-blue-600 mx-auto mb-4" />,
            },
            {
              title: "Seamless Integrations",
              description:
                "Connect effortlessly with our advanced APIs and tools.",
              icon: (
                <FaHandsHelping className="text-5xl text-blue-600 mx-auto mb-4" />
              ),
            },
          ].map((service, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-gray-50 hover:shadow-lg"
            >
              {service.icon}
              <h3 className="text-xl font-medium text-gray-800 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Luxury Travel Section */}
      <section className="py-12 bg-gradient-to-r from-blue-100 to-green-100">
        <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-12 items-center">
          <img
            src={imgLuxuryTravel}
            alt="Luxury Travel"
            className="w-full rounded-lg shadow-md"
          />
          <div>
            <h2 className="text-3xl font-bold text-green-700 mb-4">
              Elevate Your Travel Experience
            </h2>
            <p className="text-lg text-gray-700">
              tblholidays offers unmatched luxury travel services, ensuring
              unforgettable experiences for your clients. From private jets to
              premium hotel suites, every detail is tailored to perfection.
            </p>
          </div>
        </div>
      </section>

      {/* Additional Services */}
      <section className="py-12 bg-white">
        <h2 className="text-3xl font-bold text-center text-blue-700 mb-8">
          Additional Features
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
          {additionalServices.map((service, index) => (
            <div
              key={index}
              className="p-6 bg-gray-50 rounded-lg shadow hover:shadow-lg"
            >
              {service.icon}
              <h3 className="text-xl font-medium text-gray-900 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-16 bg-gradient-to-b from-green-50 to-blue-50">
        <h2 className="text-3xl font-bold text-center text-blue-700 mb-6">
          Frequently Asked Questions
        </h2>
        <div className="max-w-5xl mx-auto space-y-8">
          {faq.map((item, index) => (
            <div
              key={index}
              className="p-6 bg-white border border-gray-200 rounded-lg shadow"
            >
              <h3 className="text-lg font-bold text-green-600">
                {item.question}
              </h3>
              <p className="mt-2 text-gray-800">{item.answer}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-12 bg-gray-100">
        <h2 className="text-3xl font-bold text-center text-green-600 mb-6">
          Hear From Our Partners
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="p-6 bg-white border rounded-lg shadow hover:shadow-lg"
            >
              <p className="text-gray-700 italic mb-4">"{testimonial.quote}"</p>
              <p className="text-green-600 font-bold">{testimonial.author}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
