import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "contexts/AuthContext";
import NavbarItems from "components/navbar-items/NavbarItems";

const GlobalNavbar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);

  const onHamburgerMenuToggle = () => setIsVisible(!isVisible);

  return (
    <nav className="flex items-center justify-between px-10 py-6 bg-gradient-to-r from-blue-600 to-green-600 text-white shadow-xl">
      {/* Logo */}
      <Link
        to="/"
        className="text-4xl font-extrabold text-white tracking-wide transform hover:scale-105 transition-transform"
      >
        tblholidays
      </Link>

      {/* Main Navigation */}
      <ul className="hidden md:flex space-x-12">
        <NavbarItems isAuthenticated={isAuthenticated} />
      </ul>

      {/* Hamburger Icon */}
      <FontAwesomeIcon
        icon={faBars}
        className="text-white text-3xl cursor-pointer md:hidden"
        onClick={onHamburgerMenuToggle}
      />

      {/* Mobile Menu */}
      {isVisible && (
        <div className="absolute top-20 left-0 w-full bg-gradient-to-r from-blue-600 to-green-600 py-6 shadow-lg">
          <ul className="space-y-5 text-center">
            <NavbarItems isAuthenticated={isAuthenticated} />
          </ul>
        </div>
      )}
    </nav>
  );
};

export default GlobalNavbar;
