import React from "react";
import { Link } from "react-router-dom";

const FooterLink = ({ to, label }) => (
  <Link
    to={to}
    className="block text-green-300 hover:text-green-100 transition duration-300"
  >
    {label}
  </Link>
);

const GlobalFooter = () => {
  return (
    <footer className="bg-gradient-to-r from-blue-700 via-green-700 to-blue-800 text-white py-20">
      <div className="container mx-auto px-10 text-center">
        {/* Centralized Section */}
        <div className="space-y-8">
          <h4 className="text-4xl font-bold">Explore tblholidays</h4>
          <p className="text-lg text-green-300 max-w-3xl mx-auto">
            Your global partner for innovative B2B travel solutions. Unlock
            seamless booking, premium services, and worldwide travel tools.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-10 mt-10">
            <div>
              <FooterLink to="/about-us" label="About Us" />
              <FooterLink to="/contact" label="Contact Us" />
              <FooterLink to="/partnership" label="Partnerships" />
            </div>
            <div>
              <FooterLink to="/pricing" label="Pricing" />
              <FooterLink to="/api-solutions" label="API Solutions" />
              <FooterLink to="/our-solutions" label="Our Solutions" />
            </div>
            <div>
              <FooterLink to="/terms" label="Terms & Conditions" />
              <FooterLink to="/privacy" label="Privacy Policy" />
              <FooterLink to="/faq" label="FAQs" />
            </div>
          </div>
        </div>

        {/* Subscription & Copyright */}
        <div className="mt-14 space-y-8">
          <form className="flex justify-center">
            <input
              type="email"
              placeholder="Enter your email"
              className="w-2/3 max-w-md p-4 rounded-l-lg text-gray-800 placeholder-gray-500 focus:outline-none"
            />
            <button className="px-8 py-4 bg-green-400 text-blue-700 font-bold rounded-r-lg hover:bg-green-300 transition">
              Subscribe
            </button>
          </form>
          <p className="text-sm text-green-200">
            &copy; {new Date().getFullYear()} tblholidays. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;
