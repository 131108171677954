import React from "react";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaClock,
} from "react-icons/fa";

const Contact = () => {
  return (
    <div className="font-sans bg-gradient-to-b from-blue-50 to-green-50 text-gray-900">
      {/* Hero Section */}
      <header className="relative bg-gradient-to-r from-green-700 to-blue-700 py-20 text-center text-white">
        <h1 className="text-5xl font-extrabold">Get in Touch with tblholidays</h1>
        <p className="text-lg mt-4 max-w-3xl mx-auto">
          Have questions or need assistance? We're here to help! Reach out to us
          via the options below or send us a message.
        </p>
      </header>

      {/* Contact Information */}
      <section className="py-16 bg-white">
        <h2 className="text-4xl font-bold text-center text-green-700">
          Contact Information
        </h2>
        <p className="text-center text-lg text-gray-600 mt-4 max-w-4xl mx-auto">
          Connect with our team through multiple channels. Whether you need
          support or have business inquiries, we're always ready to assist.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-12 max-w-7xl mx-auto">
          {[
            {
              icon: (
                <FaPhoneAlt className="text-5xl text-blue-700 mx-auto mb-4" />
              ),
              title: "Call Us",
              description: "+1 (800) 877724567",
            },
            {
              icon: (
                <FaEnvelope className="text-5xl text-blue-700 mx-auto mb-4" />
              ),
              title: "Email Us",
              description: "support@tblholidays.com",
            },
            {
              icon: (
                <FaMapMarkerAlt className="text-5xl text-blue-700 mx-auto mb-4" />
              ),
              title: "Visit Us",
              description: "741 Green Street, New-York, USA",
            },
            {
              icon: <FaClock className="text-5xl text-blue-700 mx-auto mb-4" />,
              title: "Working Hours",
              description: "Mon - Fri: 9:00 AM - 6:00 PM",
            },
          ].map((info, index) => (
            <div
              key={index}
              className="p-6 bg-blue-50 rounded-lg shadow-lg hover:shadow-xl transition-shadow text-center"
            >
              {info.icon}
              <h3 className="text-2xl font-semibold text-green-700">
                {info.title}
              </h3>
              <p className="text-gray-700 mt-4">{info.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Contact Form */}
      <section className="py-16 bg-gradient-to-b from-green-50 to-blue-50">
        <h2 className="text-4xl font-bold text-center text-blue-700">
          Send Us a Message
        </h2>
        <p className="text-center text-lg text-gray-700 mt-4 max-w-3xl mx-auto">
          Fill out the form below, and our team will get back to you as soon as
          possible.
        </p>
        <div className="mt-12 max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <form className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-medium"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder="Enter your name"
                  className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-700"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-medium"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-700"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block text-gray-700 font-medium"
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                placeholder="Enter subject"
                className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-700"
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-gray-700 font-medium"
              >
                Message
              </label>
              <textarea
                id="message"
                rows="6"
                placeholder="Type your message here"
                className="w-full mt-2 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-700"
              ></textarea>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-700 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-green-700"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </section>

      {/* Map Section */}
      <section className="py-16 bg-white">
        <h2 className="text-4xl font-bold text-center text-green-700">
          Our Location
        </h2>
        <p className="text-center text-lg text-gray-600 mt-4 max-w-4xl mx-auto">
          Visit us at our headquarters to discuss your needs in person.
        </p>
        <div className="mt-12">
          <iframe
            className="w-full h-96 rounded-lg shadow-lg"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093745!2d144.95373631531682!3d-37.81627997975171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d5df0f9e7db%3A0xdb312b892a9a730b!2s123%20Green%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sus!4v1630991121817!5m2!1sen!2sus"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 bg-green-700 text-white text-center">
        <h2 className="text-4xl font-bold">
          Let's Build Something Amazing Together
        </h2>
        <p className="text-lg mt-4">
          Join tblholidays and take your travel business to the next level. Contact
          us today!
        </p>
        <button className="mt-8 bg-white text-green-700 px-10 py-4 rounded-lg shadow-lg hover:bg-gray-100">
          Contact Us Now
        </button>
      </section>
    </div>
  );
};

export default Contact;
