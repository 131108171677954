import React from "react";
import {
  FaHotel,
  FaPlane,
  FaHandshake,
  FaShieldAlt,
  FaGlobe,
} from "react-icons/fa";
import apiImage from "assets/api.png";
import successImage from "assets/success.jpg";

const Price = () => {
  return (
    <div className="font-sans bg-gradient-to-b from-blue-50 to-green-50 text-gray-900">
      {/* Hero Section */}
      <header className="relative bg-gradient-to-r from-green-700 to-blue-700 py-20 text-center text-white">
        <h1 className="text-5xl font-extrabold">
          Tailored B2B Travel Solutions
        </h1>
        <p className="text-lg mt-4 max-w-3xl mx-auto">
          Empowering travel agencies, corporate partners, and tour operators
          with innovative tools and reliable services.
        </p>
      </header>

      {/* Core Services */}
      <section className="py-16 bg-white">
        <h2 className="text-4xl font-bold text-center text-green-700">
          What We Offer
        </h2>
        <p className="text-center text-lg text-gray-600 mt-4 max-w-4xl mx-auto">
          A wide range of services to streamline operations, expand your reach,
          and create exceptional travel experiences for your clients.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-12 max-w-7xl mx-auto">
          {[
            {
              icon: <FaHotel className="text-6xl text-blue-700 mx-auto mb-4" />,
              title: "Worldwide Hotel Booking",
              description:
                "Access exclusive deals on hotels across the globe with instant booking and flexible management options.",
            },
            {
              icon: <FaPlane className="text-6xl text-blue-700 mx-auto mb-4" />,
              title: "Global Flight Reservations",
              description:
                "Book flights with real-time availability and competitive pricing for destinations worldwide.",
            },
            {
              icon: (
                <FaHandshake className="text-6xl text-blue-700 mx-auto mb-4" />
              ),
              title: "B2B Partnerships",
              description:
                "Collaborate with top-tier travel providers to expand your portfolio and create unique offers.",
            },
          ].map((service, index) => (
            <div
              key={index}
              className="p-6 bg-blue-50 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              {service.icon}
              <h3 className="text-2xl font-semibold text-green-700 text-center">
                {service.title}
              </h3>
              <p className="text-gray-700 mt-4 text-center">
                {service.description}
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Pricing Plans */}
      <section className="py-16 bg-gradient-to-r from-green-50 to-blue-50">
        <h2 className="text-4xl font-bold text-center text-blue-700">
          Flexible Pricing Plans
        </h2>
        <p className="text-center text-lg text-gray-700 mt-4 max-w-3xl mx-auto">
          Choose a plan that fits your business and start benefiting from our
          tailored solutions today.
        </p>
        <div className="flex flex-wrap justify-center gap-8 mt-12 max-w-7xl mx-auto">
          {[
            {
              name: "Basic",
              price: "$49/month",
              features: [
                "Hotel Access",
                "Flight Booking Tools",
                "Email Support",
              ],
            },
            {
              name: "Advanced",
              price: "$99/month",
              features: [
                "Global Hotel Network",
                "API Access",
                "Priority Support",
                "Partner Discounts",
              ],
            },
            {
              name: "Professional",
              price: "$149/month",
              features: [
                "Custom Integrations",
                "Dedicated Account Manager",
                "Luxury Travel Services",
                "Advanced Reporting",
              ],
            },
            {
              name: "Enterprise",
              price: "Custom Pricing",
              features: [
                "Unlimited Access",
                "24/7 Premium Support",
                "Corporate Integrations",
                "Full Customization",
              ],
            },
          ].map((plan, index) => (
            <div
              key={index}
              className={`p-6 rounded-lg shadow-lg ${
                index === 2
                  ? "bg-blue-700 text-white"
                  : "bg-white text-gray-900"
              }`}
            >
              <h3 className="text-2xl font-bold text-center">{plan.name}</h3>
              <p className="text-3xl font-extrabold mt-4 text-center">
                {plan.price}
              </p>
              <ul className="mt-6 space-y-3">
                {plan.features.map((feature, idx) => (
                  <li
                    key={idx}
                    className={`flex items-center ${
                      index === 2 ? "text-blue-100" : "text-gray-700"
                    }`}
                  >
                    ✔ {feature}
                  </li>
                ))}
              </ul>
              <button
                className={`mt-6 w-full py-3 rounded-lg ${
                  index === 2
                    ? "bg-white text-blue-700 hover:bg-gray-100"
                    : "bg-blue-700 text-white hover:bg-blue-800"
                }`}
              >
                {index === 3 ? "Contact Us" : "Get Started"}
              </button>
            </div>
          ))}
        </div>
      </section>

      {/* API Integration */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-4xl font-bold text-blue-700">
              API Integration
            </h2>
            <p className="text-lg text-gray-700 mt-4">
              Simplify your operations with our advanced API solutions for hotel
              booking, flight reservations, and tailored packages.
            </p>
          </div>
          <img
            src={apiImage}
            alt="API Integration"
            className="w-full h-72 object-cover rounded-lg shadow-md"
          />
        </div>
      </section>

      {/* Customer Success */}
      <section className="py-16 bg-gradient-to-b from-green-50 to-blue-50">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <img
            src={successImage}
            alt="Customer Success Stories"
            className="w-full h-72 object-cover rounded-lg shadow-md"
          />
          <div>
            <h2 className="text-4xl font-bold text-green-700">
              Success Stories
            </h2>
            <p className="text-lg text-gray-700 mt-4">
              Discover how travel businesses worldwide have transformed using
              tblholidays.
            </p>
          </div>
        </div>
      </section>

      {/* Comparison Table */}
      <section className="py-16 bg-white">
        <h2 className="text-4xl font-bold text-center text-blue-700">
          Compare Our Plans
        </h2>
        <div className="mt-12 overflow-x-auto max-w-7xl mx-auto">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-green-700 text-white">
                <th className="py-3 px-4 border border-gray-300">Feature</th>
                <th className="py-3 px-4 border border-gray-300">Basic</th>
                <th className="py-3 px-4 border border-gray-300">Advanced</th>
                <th className="py-3 px-4 border border-gray-300">
                  Professional
                </th>
                <th className="py-3 px-4 border border-gray-300">Enterprise</th>
              </tr>
            </thead>
            <tbody>
              {[
                ["Hotel Access", "✔", "✔", "✔", "✔"],
                ["Flight Booking Tools", "✔", "✔", "✔", "✔"],
                ["API Access", "✖", "✔", "✔", "✔"],
                ["Luxury Services", "✖", "✖", "✔", "✔"],
                ["24/7 Support", "✖", "✖", "✔", "✔"],
                ["Custom Integrations", "✖", "✖", "✔", "✔"],
              ].map((row, idx) => (
                <tr key={idx} className={idx % 2 === 0 ? "bg-gray-100" : ""}>
                  {row.map((cell, cellIdx) => (
                    <td
                      key={cellIdx}
                      className="py-3 px-4 border border-gray-300 text-center"
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 bg-blue-700 text-white text-center">
        <h2 className="text-4xl font-bold">
          Start Your Journey with tblholidays Today!
        </h2>
        <p className="text-lg mt-4">
          Join our platform and experience a seamless way to grow your travel
          business.
        </p>
        <button className="mt-8 bg-white text-blue-700 px-10 py-4 rounded-lg shadow-lg hover:bg-gray-100">
          Sign Up Now
        </button>
      </section>
    </div>
  );
};

export default Price;
