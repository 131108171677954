import { Link, useNavigate, useLocation } from "react-router-dom";

const NavbarItems = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => navigate("/login");

  const isActive = (path) => location.pathname === path;

  return (
    <>
      {["/", "/pricing", "/api-solutions", "/about-us", "/contact"].map(
        (path, index) => (
          <li key={index} className="transition duration-300">
            <Link
              to={path}
              className={`uppercase text-lg font-medium tracking-wider ${
                isActive(path)
                  ? "text-green-200 border-b-2 border-green-200"
                  : "text-white hover:text-green-200"
              }`}
            >
              {path === "/" ? "Home" : path.substring(1).replace("-", " ")}
            </Link>
          </li>
        )
      )}
      <li>
        {isAuthenticated ? (
          <button
            onClick={handleLogout}
            className="uppercase text-lg font-medium tracking-wider text-white hover:text-green-200"
          >
            Logout
          </button>
        ) : (
          <Link
            to="/login"
            className="uppercase text-lg font-medium tracking-wider text-white hover:text-green-200"
          >
            Login
          </Link>
        )}
      </li>
    </>
  );
};

export default NavbarItems;
