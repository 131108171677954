import React from "react";
import {
  FaPlane,
  FaHotel,
  FaUsers,
  FaHandshake,
  FaRocket,
  FaRegLightbulb,
  FaGlobeAmericas,
  FaChartLine,
  FaLocationArrow,
} from "react-icons/fa";
import teamPhoto from "assets/team.jpg";
import officePhoto from "assets/officePhoto.jpg";

const AboutUs = () => {
  return (
    <div className="font-sans bg-gradient-to-b from-blue-50 to-green-50 text-gray-900">
      {/* Hero Section */}
      <header className="relative bg-gradient-to-r from-blue-600 to-green-600 py-20 text-center text-white">
        <h1 className="text-5xl font-extrabold">Welcome to tblholidays</h1>
        <p className="text-lg mt-6 max-w-3xl mx-auto">
          Redefining B2B travel services with innovative solutions for agencies,
          travel operators, and businesses across the globe.
        </p>
      </header>

      {/* Who We Are */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-4xl font-semibold text-green-600">
              Who We Are
            </h2>
            <p className="text-lg text-gray-600 mt-4 leading-relaxed">
              Founded in 2018, tblholidays was created to revolutionize the travel
              industry by providing cutting-edge technology to help businesses
              grow. We connect travel agencies with a global network of hotels,
              airlines, and services, ensuring smoother operations and more
              efficient travel management.
            </p>
            <p className="text-lg text-gray-600 mt-4 leading-relaxed">
              Today, we are trusted by thousands of businesses worldwide to
              deliver seamless travel solutions, empower their operations, and
              create extraordinary travel experiences.
            </p>
          </div>
          <img
            src={officePhoto}
            alt="Modern Office"
            className="w-full h-80 object-cover rounded-lg shadow-xl"
          />
        </div>
      </section>

      {/* Our Mission */}
      <section className="py-16 bg-gray-100">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <img
            src={teamPhoto}
            alt="Our Team"
            className="w-full h-80 object-cover rounded-lg shadow-xl"
          />
          <div>
            <h2 className="text-4xl font-semibold text-green-600">
              Our Mission
            </h2>
            <p className="text-lg text-gray-600 mt-4 leading-relaxed">
              At tblholidays, our mission is simple: to offer unparalleled value
              through innovation and collaboration. We provide travel businesses
              with the tools to not only compete but to lead in the rapidly
              changing travel industry.
            </p>
            <p className="text-lg text-gray-600 mt-4 leading-relaxed">
              By focusing on sustainable, customer-first solutions, we make sure
              that our partners can deliver unforgettable experiences to
              travelers everywhere.
            </p>
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="py-16 bg-white">
        <h2 className="text-4xl font-semibold text-center text-green-600">
          Why Partner with tblholidays?
        </h2>
        <p className="text-lg text-gray-600 text-center mt-4 max-w-4xl mx-auto">
          With an unmatched combination of innovation, global reach, and
          tailored services, we empower your business with the tools needed to
          succeed in today’s competitive travel market.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12 max-w-6xl mx-auto">
          {[
            {
              icon: (
                <FaRocket className="text-5xl text-green-500 mx-auto mb-4" />
              ),
              title: "Innovation at Its Core",
              description:
                "Stay ahead of the curve with advanced travel solutions that anticipate market trends.",
            },
            {
              icon: (
                <FaHandshake className="text-5xl text-green-500 mx-auto mb-4" />
              ),
              title: "Strong Partnerships",
              description:
                "Build lasting relationships based on trust, mutual growth, and shared goals.",
            },
            {
              icon: (
                <FaGlobeAmericas className="text-5xl text-green-500 mx-auto mb-4" />
              ),
              title: "Global Reach",
              description:
                "Access a comprehensive portfolio of global hotels, airlines, and services with ease.",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="bg-gradient-to-t from-green-100 to-white p-6 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-200"
            >
              {item.icon}
              <h3 className="text-2xl font-semibold text-green-600">
                {item.title}
              </h3>
              <p className="text-gray-700 mt-4">{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Core Values */}
      <section className="py-16 bg-gray-100">
        <h2 className="text-4xl font-semibold text-center text-green-600">
          Our Core Values
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12 max-w-6xl mx-auto">
          {[
            {
              icon: (
                <FaRegLightbulb className="text-5xl text-green-500 mx-auto mb-4" />
              ),
              title: "Innovation",
              description:
                "Pioneering new solutions that reshape the travel industry.",
            },
            {
              icon: (
                <FaUsers className="text-5xl text-green-500 mx-auto mb-4" />
              ),
              title: "Teamwork",
              description: "Collaboration and support define the way we work.",
            },
            {
              icon: (
                <FaLocationArrow className="text-5xl text-green-500 mx-auto mb-4" />
              ),
              title: "Sustainability",
              description:
                "We prioritize eco-friendly travel solutions that benefit both travelers and the planet.",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="p-6 bg-white rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-200"
            >
              {item.icon}
              <h3 className="text-2xl font-semibold text-green-600">
                {item.title}
              </h3>
              <p className="text-gray-700 mt-4">{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Partner with Us */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div>
            <h2 className="text-4xl font-semibold text-green-600">
              Partner with Us
            </h2>
            <p className="text-lg text-gray-600 mt-4">
              At tblholidays, we are committed to forging strong, mutually
              beneficial partnerships. Whether you're a travel agency or a
              large-scale tour operator, we’re here to help you grow and
              succeed.
            </p>
            <p className="text-lg text-gray-600 mt-4">
              Together, we can create smarter, more efficient travel solutions
              for businesses and travelers alike.
            </p>
          </div>
          <FaChartLine className="text-9xl text-green-500 mx-auto" />
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 bg-green-600 text-white text-center">
        <h2 className="text-4xl font-semibold">
          Ready to Take Your Travel Business to the Next Level?
        </h2>
        <p className="text-lg mt-4 max-w-4xl mx-auto">
          Join tblholidays and access cutting-edge tools, a global network, and
          unparalleled support to grow your business.
        </p>
        <button className="mt-8 bg-white text-green-600 px-10 py-4 rounded-lg shadow-lg hover:bg-gray-100">
          Get Started
        </button>
      </section>
    </div>
  );
};

export default AboutUs;
