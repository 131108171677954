import React from "react";
import {
  FaCloud,
  FaCogs,
  FaShieldAlt,
  FaCode,
  FaRocket,
  FaServer,
} from "react-icons/fa";
import integrationImage from "assets/digital.jpg";
import dashboardImage from "assets/transformation.webp";

const ApiSolutions = () => {
  return (
    <div className="font-sans bg-gradient-to-b from-purple-50 to-purple-200 text-gray-900">
      {/* Hero Section */}
      <header className="relative bg-gradient-to-r from-purple-700 to-indigo-700 py-20 text-center text-white">
        <h1 className="text-5xl font-extrabold">
          Unlock Seamless Travel Experiences with Our API
        </h1>
        <p className="text-lg mt-4 max-w-3xl mx-auto">
          Transform your travel business with reliable API solutions for hotel
          bookings, flights, and end-to-end travel management.
        </p>
      </header>

      {/* Features Section */}
      <section className="py-16 bg-white">
        <h2 className="text-4xl font-bold text-center text-purple-700">
          Key Benefits of Our API
        </h2>
        <p className="text-center text-lg text-gray-600 mt-4 max-w-4xl mx-auto">
          Experience the power of global connectivity, flexibility, and
          top-notch security with our advanced API solutions.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12 max-w-7xl mx-auto">
          {[
            {
              icon: (
                <FaCloud className="text-6xl text-purple-700 mx-auto mb-4" />
              ),
              title: "Cloud Integration",
              description:
                "99.9% uptime and instant global access to travel data.",
            },
            {
              icon: (
                <FaCogs className="text-6xl text-purple-700 mx-auto mb-4" />
              ),
              title: "Customizable Workflows",
              description:
                "Adaptable features tailored to your unique business needs.",
            },
            {
              icon: (
                <FaShieldAlt className="text-6xl text-purple-700 mx-auto mb-4" />
              ),
              title: "Secure Transactions",
              description:
                "Encrypted communication and compliance with industry standards.",
            },
          ].map((feature, index) => (
            <div
              key={index}
              className="p-6 bg-purple-50 rounded-lg shadow-lg hover:shadow-xl transition-shadow text-center"
            >
              {feature.icon}
              <h3 className="text-2xl font-semibold text-purple-700">
                {feature.title}
              </h3>
              <p className="text-gray-700 mt-4">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-16 bg-gradient-to-b from-purple-100 to-purple-50">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-4xl font-bold text-purple-700">How It Works</h2>
            <p className="text-lg text-gray-700 mt-4">
              Easily integrate our API to gain access to a comprehensive travel
              inventory and manage bookings with ease.
            </p>
            <ul className="mt-6 space-y-4 text-gray-700">
              {[
                {
                  icon: <FaCode className="text-purple-700 text-xl mr-4" />,
                  title: "Integration",
                  description:
                    "Quick setup with comprehensive SDKs and documentation.",
                },
                {
                  icon: <FaRocket className="text-purple-700 text-xl mr-4" />,
                  title: "Launch",
                  description:
                    "Go live quickly and start offering premium travel services.",
                },
                {
                  icon: <FaServer className="text-purple-700 text-xl mr-4" />,
                  title: "Scale",
                  description:
                    "Expand your operations seamlessly with scalable infrastructure.",
                },
              ].map((step, index) => (
                <li key={index} className="flex items-start">
                  {step.icon}
                  <span>
                    <strong>{step.title}:</strong> {step.description}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <img
            src={integrationImage}
            alt="API Integration"
            className="w-full h-80 object-cover rounded-lg shadow-lg"
          />
        </div>
      </section>

      {/* API Dashboard Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <img
            src={dashboardImage}
            alt="API Dashboard"
            className="w-full h-80 object-cover rounded-lg shadow-md"
          />
          <div>
            <h2 className="text-4xl font-bold text-purple-700">
              API Dashboard
            </h2>
            <p className="text-lg text-gray-700 mt-4">
              Manage your travel services effortlessly through our user-friendly
              dashboard.
            </p>
            <ul className="mt-6 space-y-4 text-gray-700">
              <li>Real-time analytics for better decision-making.</li>
              <li>Simple configuration of API settings and preferences.</li>
              <li>Access to advanced tools for premium features.</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="py-16 bg-purple-700 text-white text-center">
        <h2 className="text-4xl font-bold">Start Your Journey Today</h2>
        <p className="text-lg mt-4">
          Take your travel business to new heights with our reliable API
          solutions.
        </p>
        <button className="mt-8 bg-white text-purple-700 px-10 py-4 rounded-lg shadow-lg hover:bg-gray-100">
          Get Started Now
        </button>
      </section>
    </div>
  );
};

export default ApiSolutions;
