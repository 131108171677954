import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Toast from "components/ux/toast/Toast";
import { REGISTRATION_MESSAGES } from "utils/constants";
import { Formik, Form, Field } from "formik";
import Schemas from "utils/validation-schemas";

const Register = () => {
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [showToast, setShowToast] = useState(false);

  const handleSubmit = async (values) => {
    setToastType("error");
    setShowToast(true);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-orange-800 via-gray-900 to-black px-4">
      <div className="max-w-md w-full p-6 shadow-lg bg-gray-800 rounded-lg transform transition-all hover:scale-105">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Schemas.signupSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched }) => (
            <Form className="p-8 bg-gray-900 rounded-lg text-white">
              <h2 className="text-3xl font-bold text-orange-400 text-center mb-4">
                Join Us
              </h2>
              <p className="text-center text-gray-400 mb-6">
                Create your account to get started
              </p>

              <div className="flex flex-wrap mb-4 -mx-2">
                <div className="w-1/2 px-2">
                  <Field
                    name="firstName"
                    placeholder="First Name"
                    className={`w-full bg-gray-700 border rounded-lg py-2 px-4 ${errors.firstName && touched.firstName ? "border-red-500" : "border-gray-600"}`}
                  />
                </div>
                <div className="w-1/2 px-2">
                  <Field
                    name="lastName"
                    placeholder="Last Name"
                    className={`w-full bg-gray-700 border rounded-lg py-2 px-4 ${errors.lastName && touched.lastName ? "border-red-500" : "border-gray-600"}`}
                  />
                </div>
              </div>

              <Field
                name="email"
                placeholder="Email"
                className={`w-full mb-4 bg-gray-700 border rounded-lg py-2 px-4 ${errors.email && touched.email ? "border-red-500" : "border-gray-600"}`}
              />

              <Field
                name="phoneNumber"
                placeholder="Phone"
                className={`w-full mb-4 bg-gray-700 border rounded-lg py-2 px-4 ${errors.phoneNumber && touched.phoneNumber ? "border-red-500" : "border-gray-600"}`}
              />

              <Field
                name="password"
                placeholder="Password"
                className={`w-full mb-4 bg-gray-700 border rounded-lg py-2 px-4 ${errors.password && touched.password ? "border-red-500" : "border-gray-600"}`}
              />

              <Field
                name="confirmPassword"
                placeholder="Confirm Password"
                className={`w-full mb-6 bg-gray-700 border rounded-lg py-2 px-4 ${errors.confirmPassword && touched.confirmPassword ? "border-red-500" : "border-gray-600"}`}
              />

              <button
                type="submit"
                className="w-full bg-orange-400 text-black font-semibold py-2 rounded-lg transition-all transform hover:bg-orange-300 hover:scale-105 focus:outline-none"
              >
                Register
              </button>

              <div className="text-center mt-4 text-gray-400">
                Already have an account?
              </div>
              <Link
                to="/login"
                className="text-center block mt-2 text-orange-400 hover:underline"
              >
                Back to login
              </Link>
            </Form>
          )}
        </Formik>
        {showToast && (
          <Toast type={toastType} message={toastMessage} dismissError />
        )}
      </div>
    </div>
  );
};

export default Register;
